document.addEventListener('DOMContentLoaded', function () {
    const reviews = document.querySelectorAll('.review')
    if(!reviews) {
        return
    }
    reviews.forEach((review) => {
      const shortText = review.querySelector('.short-text')
      const fullText = review.querySelector('.full-text')
      const readMoreLink = review.querySelector('.read-more-link')
      if(readMoreLink && !readMoreLink.dataset.listenerAdded) {
        readMoreLink.dataset.listenerAdded = true
        readMoreLink.addEventListener('click', function (e) {
            e.preventDefault()
            if (shortText.classList.contains('hidden')) {
                shortText.classList.remove('hidden')
                fullText.classList.add('hidden')
                readMoreLink.textContent = readMoreLink.dataset.more
            } else if (fullText.classList.contains('hidden')) {
                fullText.classList.remove('hidden')
                shortText.classList.add('hidden')
                readMoreLink.textContent = readMoreLink.dataset.less
            }
          })
      }
    })
})
